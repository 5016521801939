<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="supplierModal"
    >   
        <CForm>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Contacto:"
                  :lazy="false"
                  :value.sync="$v.form.contact_name.$model"
                  placeholder=""
                  autocomplete="contact-name"
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="RFC:"
                  :lazy="false"
                  :value.sync="$v.form.rfc.$model"
                  :isValid="checkIfValid('rfc')"
                  placeholder=""
                  autocomplete="rfc"
                  maxLength="13"
                  invalidFeedback="Este campo debe estar conformado 12 o 13 caracteres."
              />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Razón Social:"
                  :lazy="false"
                  :value.sync="$v.form.fiscal_name.$model"
                  placeholder=""
                  autocomplete="fiscal-name"
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="8">
                <CInput
                  label="Domicilio:"
                  :lazy="false"
                  :value.sync="$v.form.address.$model"
                  placeholder=""
                  autocomplete="address"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Exterior:"
                  :lazy="false"
                  :value.sync="$v.form.exterior.$model"
                  placeholder=""
                  autocomplete="exterior"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Interior:"
                  :lazy="false"
                  :value.sync="$v.form.interior.$model"
                  placeholder=""
                  autocomplete="interior"
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="5">
                <CInput
                  label="Colonia:"
                  :lazy="false"
                  :value.sync="$v.form.colony.$model"
                  placeholder=""
                  autocomplete="colony"
                />
              </CCol>
              <CCol md="5">
                <CInput
                  label="Ciudad:"
                  :lazy="false"
                  :value.sync="$v.form.city.$model"
                  placeholder=""
                  autocomplete="city"
                />
              </CCol>
              <CCol md="2">
                <CInput
                  label="C.P.:"
                  :lazy="false"
                  :value.sync="$v.form.zipcode.$model"
                  :isValid="checkIfValid('zipcode')"
                  placeholder=""
                  autocomplete="zipcode"
                  maxLength="5"
                  invalidFeedback="Este campo debe estar conformado 5 dígitos numéricos."
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="Estado:"
                  :lazy="false"
                  :value.sync="$v.form.state.$model"
                  placeholder=""
                  autocomplete="state"
              />
              </CCol>
              <CCol md="6">
                <CInput
                  label="País:"
                  :lazy="false"
                  :value.sync="$v.form.country.$model"
                  placeholder=""
                  autocomplete="country"
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="Correo Electrónico"
                  type="email"
                  :lazy="false"
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                  placeholder=""
                  autocomplete="email"
                  invalidFeedback="Este campo debe ser un correo electrónico válido."
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Teléfono:"
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="phone"
                  maxLength="10"
                  invalidFeedback="Este campo debe estar conformado 10 dígitos numéricos."
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Celular:"
                  :lazy="false"
                  :value.sync="$v.form.mobile.$model"
                  :isValid="checkIfValid('mobile')"
                  placeholder=""
                  autocomplete="mobile"
                  maxLength="10"
                  invalidFeedback="Este campo debe estar conformado 10 dígitos numéricos."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Página Web"
                  type="webpage"
                  :lazy="false"
                  :value.sync="$v.form.webpage.$model"
                  placeholder=""
                  autocomplete="webpage"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Forma de Pago:"
                  :lazy="false"
                  :value.sync="$v.form.payment_form.$model"
                  placeholder=""
                  autocomplete="payment_form"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Observaciones"
                  type="observations"
                  :lazy="false"
                  :value.sync="$v.form.observations.$model"
                  placeholder=""
                  autocomplete="observations"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
  name: 'SupplierModal',
  props: {

  },
  
  data () {
    return {
      supplierModal: false,
      title: "Nuevo Proveedor",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false
    }
  },
  methods: {
    storeModal () {
      this.supplierModal = true;
      this.color = "info";
      this.title = 'Nuevo Proveedor';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.supplierModal = true;
      this.color = "warning";
      this.title = 'Editar Proveedor';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          contact_name: item.contact_name,
          rfc: item.rfc,
          fiscal_name: item.fiscal_name,
          address: item.address,
          exterior: item.exterior,
          interior: item.interior,
          colony: item.colony,
          city: item.city,
          zipcode: item.zipcode,
          state: item.state,
          country: item.country,
          name: item.name,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          webpage: item.webpage,
          payment_form: item.payment_form,
          observations: item.observations
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.supplierModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          contact_name: '',
          rfc: '',
          fiscal_name: '',
          address: '',
          exterior: '',
          interior: '',
          colony: '',
          city: '',
          zipcode: '',
          state: '',
          country: '',
          email: '',
          phone: '',
          mobile: '',
          webpage: '',
          payment_form: '',
          observations: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      contact_name: {},
      rfc: {
        minLength: minLength(12),
        maxLength: maxLength(13)
      },
      fiscal_name: {},
      address: {},
      exterior: {},
      interior: {},
      colony: {},
      city: {},
      zipcode: {
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      },
      state: {},
      country: {},
      email: {
        email
      },
      phone: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      mobile: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      webpage: {},
      payment_form: {},
      observations: {}
    }
  },
}
</script>